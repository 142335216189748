import { hamdaniaImages, technologyImages } from "../utils/images";
import { technologies } from "../utils/keywords";

export const technologiesUsedInHamdania = [
  { title: technologies.reactNative, logo: technologyImages.react },
  { title: technologies.typescript, logo: technologyImages.typescript },
];

export const hamdaniaFeatures = [
  {
    title: "Holy Quran with Urdu Translation",
    description:
      "Read the complete Holy Quran with Urdu translation. Users can toggle the translation on or off as per their preference.",
    color: "bg-blue-100",
  },
  {
    title: "Tasbeeh Counter System",
    description:
      "Set and achieve Tasbeeh targets with a system that notifies you upon target completion.",
    color: "bg-green-100",
  },
  {
    title: "Islamic Calendar",
    description:
      "Keep track of Islamic dates and special occasions with our comprehensive Islamic calendar.",
    color: "bg-yellow-100",
  },
  {
    title: "Random Hadith on Home Screen",
    description:
      "Discover a new Hadith every time you open the app for daily inspiration.",
    color: "bg-purple-100",
  },
  {
    title: "Special Islamic Day Notifications",
    description:
      "Receive timely notifications on over 100 special Islamic days throughout the year.",
    color: "bg-red-100",
  },
  {
    title: "9 Basic Islamic Kalimas",
    description: "Learn and recite the 9 basic Islamic Kalimas with ease.",
    color: "bg-blue-200",
  },
  {
    title: "Aqwal-e-Zareen",
    description: "Explore a collection of inspiring Islamic quotes.",
    color: "bg-green-200",
  },
  {
    title: "Qibla Direction",
    description: "Find the Qibla direction easily from wherever you are.",
    color: "bg-yellow-200",
  },
  {
    title: "Additional Features",
    description:
      "Includes Dawaat, Qasida, Noha, and much more to enrich your Islamic journey.",
    color: "bg-purple-200",
  },
];

export const hamdaniaKeywords = {
  name: "مشرب ہمدانیہ",
  description: "Religious App in React Native",
  videoLink: "",
  detail: "",
};

export const hamdaniaAppImages = [
  hamdaniaImages.logo,
  hamdaniaImages.splash,
  hamdaniaImages.img1,
  hamdaniaImages.img2,
  hamdaniaImages.img3,
  hamdaniaImages.img4,
  hamdaniaImages.img5,
  hamdaniaImages.img6,
  hamdaniaImages.img7,
  hamdaniaImages.img8,
  hamdaniaImages.img9,
  hamdaniaImages.img10,
  hamdaniaImages.img11,
  hamdaniaImages.img12,
  hamdaniaImages.img13,
  hamdaniaImages.img14,
];
