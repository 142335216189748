import React, { useState, useEffect } from "react";
import { keywords } from "../utils/keywords";
import { globalImages } from "../utils/images";

// const email = process.env.REACT_APP_EMAIL_ADDRESS;
// const whatsappNumber = process.env.REACT_APP_WHATSAPP_NUMBER;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const [showStatus, setShowStatus] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const response = await fetch("https://formspree.io/f/xqazjwan", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      setStatus("Message sent successfully!");
    } else {
      setStatus("Oops! Something went wrong.");
    }

    setShowStatus(true);

    // Hide status message after 3 seconds
    setTimeout(() => {
      setShowStatus(false);
    }, 3000);
  };

  useEffect(() => {
    // Clear status message after animation
    if (!showStatus) {
      setStatus("");
    }
  }, [showStatus]);

  return (
    <section className="bg-secondery px-5 py-20" id="contact">
      <div className="container mx-auto">
        <h2 className="text-4xl text-white font-bold mb-6 border-b-4 border-indigo-500 inline-block">
          {keywords.contactUs}
        </h2>
        <p className="text-white mb-10 text-lg md:text-xl lg:w-8/12 ">
          {keywords.contacUsMessage}
        </p>

        <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start gap-8">
          {/* Contact Form */}
          <div className="w-full lg:w-1/2 bg-primary rounded-lg shadow-lg p-8">
            <h3 className="text-2xl font-semibold text-white mb-4">
              {keywords.sendUsMessage}
            </h3>
            <form onSubmit={handleSubmit}>
              <label className="block mb-4 text-left">
                <span className="text-lg font-medium text-white">
                  {keywords.name}
                </span>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  required
                />
              </label>

              <label className="block mb-4 text-left">
                <span className="text-lg font-medium text-white">
                  {keywords.email}
                </span>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  required
                />
              </label>

              <label className="block mb-4 text-left">
                <span className="text-lg font-medium text-white">
                  {keywords.message}
                </span>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="mt-1 block w-full py-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50"
                  rows={4}
                  required
                ></textarea>
              </label>

              <button
                type="submit"
                className="w-full bg-primary border-2 border-[#7477FF] text-white py-4 px-4 rounded-md shadow-md hover:bg-[#021f2d;] focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                {keywords.sendMessage}
              </button>

              {/* Status Message */}
              {status && (
                <div
                  className={`mt-4 text-lg ${
                    status.includes("successfully")
                      ? "text-green-500"
                      : "text-red-500"
                  } ${
                    showStatus
                      ? "opacity-100 translate-y-0"
                      : "opacity-0 translate-y-4"
                  } transition-opacity transform duration-300`}
                >
                  {status}
                </div>
              )}
            </form>
          </div>

          {/* Contact Information */}
          {/* <div className="w-full lg:w-1/2 bg-primary rounded-lg shadow-lg p-8">
            <h3 className="text-2xl font-semibold text-white mb-4">
              Contact Information
            </h3>
            <p className="text-lg text-white mb-4">
              <span className="font-semibold">Email:</span> {email}
            </p>
            <p className="text-lg text-white mb-4">
              <span className="font-semibold">WhatsApp:</span> {whatsappNumber}
            </p>
          </div> */}
          <div className="mt-8 flex justify-center">
            <img
              src={globalImages.contactUs}
              alt="coding illustration"
              className="w-full max-w-md rounded-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
