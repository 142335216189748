import { useEffect, useState } from "react";
import {
  FaThumbsUp,
  FaLaptopCode,
  FaProjectDiagram,
  FaCode,
} from "react-icons/fa";
import { keywords } from "src/utils/keywords";
import { DispatchType } from "src/utils/types";

const Metrics = () => {
  const [clients, setClients] = useState(0);
  const [projects, setProjects] = useState(0);
  const [linesOfCode, setLinesOfCode] = useState(0);
  const [teamMembers, setTeamMembers] = useState(0);

  useEffect(() => {
    const increment = (setFunction: DispatchType<number>, maxValue: number, duration: number) => {
      let start = 0;
      const step = Math.ceil((maxValue / duration) * 50);
      const timer = setInterval(() => {
        start += step;
        if (start >= maxValue) {
          setFunction(maxValue);
          clearInterval(timer);
        } else {
          setFunction(start);
        }
      }, 50);
    };

    increment(setClients, 5, 2000); // Happy Clients
    increment(setProjects, 12, 2000); // Completed Projects
    increment(setLinesOfCode, 93924, 2000); // Lines of Code
    increment(setTeamMembers, 4, 2000); // Team Members
  }, []);

  return (
    <section className="bg-primary px-5 py-20 " id="acheivements">
      <div className="container mx-auto">
        <h2 className="text-4xl text-white font-bold mb-6 border-b-4 border-indigo-500 inline-block">
          {keywords.achievements}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 text-center my-28">
          {/* Happy Clients */}
          <div className="flex flex-col items-center">
            <FaThumbsUp className="text-accent text-6xl mb-8" />
            <h3 className="text-5xl font-bold text-white">{clients}</h3>
            <p className="text-white text-lg">{keywords.happyClients}</p>
          </div>

          {/* Completed Projects */}
          <div className="flex flex-col items-center">
            <FaProjectDiagram className="text-accent text-6xl mb-8" />
            <h3 className="text-5xl font-bold text-white">{projects}</h3>
            <p className="text-white text-lg">{keywords.completedProjects}</p>
          </div>

          {/* Lines of Code */}
          <div className="flex flex-col items-center">
            <FaCode className="text-accent text-6xl mb-8" />
            <h3 className="text-5xl font-bold text-white">{linesOfCode}</h3>
            <p className="text-white text-lg">{keywords.lineOfCode}</p>
          </div>

          {/* Team Members */}
          <div className="flex flex-col items-center">
            <FaLaptopCode className="text-accent text-6xl mb-8" />
            <h3 className="text-5xl font-bold text-white">{teamMembers}</h3>
            <p className="text-white text-lg">{keywords.teamMembers}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Metrics;
