import "react-slideshow-image/dist/styles.css";

function ImageSlider({ images }: { images: string[] }) {
  console.log(images);
  if (images) {
    return (
      <div className="slide-container my-16">
        <div className="container mx-auto grid md:grid-cols-4 gap-10  grid-4 sm:grid-cols-2">
          {images?.map((image, index) => (
            <img
              key={index}
              src={image}
              className="w-60 h-96 hover:w-85 hover:h-98 transition-all duration-300 ease-in-out transform hover:scale-110 rounded-lg border-2"
              style={{objectFit:'fill'}}
            />
          ))}
        </div>
      </div>
    );
  } else return <></>;
}

export default ImageSlider;
