import { keywords } from "../utils/keywords";
import { teams } from "../constants";

const OurTeam = () => {
  return (
    <section className="section py-20 bg-white bg-primary" id="team">
      <div className="container mx-auto grid md:grid-cols-2 items-center md:justify-between">
        <div className="about-info mb-5">
          <h2 className="w-[160px] text-4xl text-white font-bold mb-5 border-b-[5px] w-[180px] border-indigo-600 pb-2">
            {keywords.ourTeam}
          </h2>
          <p className="pb-5 text-white">{keywords.quoteMessage}</p>
        </div>
      </div>
      <div className="container mx-auto px-6 md:px-12 lg:px-8">
        <div className="py-20 grid gap-28 md:gap-12 md:grid-cols-2">
          {teams.map((team) => (
            <div
              className="border-t-4 space-y-4  group text-center mb-12 align-center"
              key={team.id}
            >
              <div className="w-32 h-32 -mt-16  mx-auto rounded-[2rem]  rotate-45 overflow-hidden">
                <img
                  src={team?.image || "./image.jpg"}
                  alt=" team member"
                  loading="lazy"
                  width="640"
                  height="805"
                  className="w-full h-full object-cover -rotate-45 scale-125 mx-auto  transition duration-1000 group-hover:sclae-[1.7]"
                />
              </div>
              <div className="text-center ">
                <h5 className="text-xl text-white font-semibold">
                  {team.name}
                </h5>
                <span className=" text-sm text-white "> {team.role}</span>
              </div>
              {/* <a
                href="#"
                className="block w-max mx-auto text-center text-blue-500"
              >
                {keywords.viewBio}
              </a> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
