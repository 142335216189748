import React from "react";
import { keywords } from "../utils/keywords";
import { applications, backendTechnologies, devOps, frontendTechnologies } from "../constants";

function Technologies() {
  const data = [
    {
      title: keywords.frontEnd,
      icons: frontendTechnologies,
    },
    {
      title:keywords.backend,
      icons: backendTechnologies,
    },
    {
      title: keywords.appDevelopment,
      icons: applications,
    },
    {
      title: "DevOps",
      icons: devOps,
    },
  ];
  return (
    <section className="bg-primary text-white px-5 py-32" id="technologies">
      <div className="container mx-auto grid md:grid-cols-2 items-center md:justify-between">
        <div className="about-info mb-5">
          <h2 className="text-4xl font-bold mb-5 border-b-[5px] max-w-full w-auto border-indigo-600 pb-2">
            {keywords.ourSkillsAndTechnologies}
          </h2>
        </div>
      </div>
      <div className="projects container mx-auto gap-10">
        {data.map((item, index) => (
          <div key={index} className="my-8">
            <hr className="border-2 my-5" />
            <div className="my-5 text-center">
              <h2 className="text-3xl">{item.title}</h2>
            </div>
            <hr className="border-2 my-5" />
            <div className="flex flex-wrap justify-center gap-8">
              {item.icons.images.map((logo, index) => (
                <div
                  key={index}
                  className="flex flex-col items-center my-5 px-4 md:px-8"
                >
                  <img
                    className="rounded-lg w-32 h-32 mb-3"
                    src={logo.logo}
                    alt={logo.title}
                  />
                  <h4 className="text-center text-xl md:text-2xl">
                    {logo.title}
                  </h4>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Technologies;
