export const technologies = {
  react: "React JS",
  reactNative: "React-Native",
  express: "Express JS",
  node: "Node JS",
  mongodb: "Mongo DB",
  postgresql: "PostgreSQL",
  graphql: "Graph QL",
  docker: "Docker",
  kubernetes: "Kubernetes",
  aws: "AWS",
  azure: "Azure",
  googleCloud: "Google Cloud",
  javascript: "Javascript",
  typescript: "Typescript",
  html: "HTML",
  css: "CSS",
  firebase: "Firebase",
  supabase: "Supabase",
  twilio: "Twilio",
  stripe: "Stripe",
  paypal: "Paypal",
  iap: "In App Subscription",
  tailwind: "Tailwind CSS",
  swift: "Swift",
  xcode: "Xcode",
  androidStudio: "Android Studio",
  cicd: "CI/CD",
  java: "Java",
};

export const keywords = {
  companyName: "InnuNext Technologies",
  about: "About",
  projects: "Projects",
  blog: "Blog",
  contact: "Contact",
  resume: "Resume",
  seeProjects: "See Projects",
  achievements: "Our Achievements",
  ourTeam: "Our Team",
  happyClients: "Happy Clients",
  completedProjects: "Completed Projects",
  lineOfCode: "Lines of Code",
  teamMembers: "Team Members",
  achievement: "Achievements",
  quoteMessage:
    '"Coming together is a beginning, staying together is progress, and working together is success." – Henry Ford',

  description:
    " where innovation meets technology! We're a dynamic software house dedicated to crafting exceptional web and mobile applications that empower businesses and individuals to thrive in the digital era. With a passion for cutting-edge solutions and a team of experienced developers, we're here to transform your ideas into interactive, user-friendly, and feature-rich software products. Explore the endless possibilities with InnuNext Technologies and let's embark on a journey of digital transformation together.",
  aboutUs: "About Us",
  aboutUsPara1:
    "At InnuNext Technologies, we are not just another software development company; we are your partners in innovation. Our journey began with a vision to revolutionize the digital landscape, and today, we stand as a leading force in the world of web and mobile app development.",
  aboutUsPara2:
    "What sets us apart is our unwavering commitment to excellence, a passion for technology, and a relentless pursuit of perfection. With a dedicated team of creative minds and technical experts, we've successfully delivered tailored solutions to clients across various industries, consistently exceeding their expectations.",
  aboutUsPara3:
    "InnuNext Technologies is more than a software development company; we are the architects of digital transformation. Whether you're a startup looking to make your mark or an established business seeking a digital upgrade, we're here to make your vision a reality. Join us on this exciting journey, and together, we'll redefine what's possible in the world of web and mobile app development. Discover the InnuNext Technologies difference, and let's shape the future together",
  blogs: "Blogs",
  someOfOurBestBlogs: "Some of our best blogs.",
  readMore: "Read More",
  contactUs: "Contact Us",
  contacUsMessage:
    "Ready to turn your digital dreams into reality? Reach out to our expert team of software developers today, and let's embark on a journey to create cutting-edge solutions tailored to your needs.",
  email: "Email",
  whatsapp: "Whatsapp",
  allRightReserved: "InnuNext Technologies all right reserved",
  welcomeTo: "Welcome to ",
  viewBio: "View Bio",
  projectDescription:
    " Here are some of our projects. We have built with React, React-Native, Firebase, AWS, Twilio, Supabase, NodeJS and vanilla CSS. Check them out. ",
  moreDetails: "More Details",
  videoDemo: "Video Demo",
  ourSkillsAndTechnologies: "Our Skills & Technologies",
  clients: "Clients",
  ourClients: "Our Clients",
  ourClientMessage: "Here’s what our clients have to say about us.",
  sendUsMessage: "Send us a Message",
  name: "Name",
  message: "Message",
  sendMessage: "Send Message",
  frontEnd: "Front-end",
  backend: "Backend",
  appDevelopment: "App Development",
};
