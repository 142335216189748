import React from "react";
import { keywords } from "../utils/keywords";

const Footer = () => {
  return (
    <div className="py-4 text-center bg-primary text-white ">
      &copy; {new Date().getFullYear()} {keywords.allRightReserved}
    </div>
  );
};

export default Footer;
