import React from "react";
import {
  AiOutlineFacebook,
  AiOutlineInstagram,
  AiOutlineLinkedin,
  AiOutlineTwitter,
  AiOutlineYoutube,
} from "react-icons/ai";
import { keywords } from "../utils/keywords";
import { globalImages } from "../utils/images";
import { links } from "../constants";

const Hero = () => {
  return (
    <section className="bg-primary px-5 text-white py-32">
      <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between">
        <div className="hero-info pb-5 md:pb-0">
          <h1 className="text-4xl lg:text-6xl">
            <br />
            {keywords.welcomeTo}
            <br />
            <span className="text-accent">{keywords.companyName}</span>
          </h1>

          <p className="py-5">{keywords.description}</p>

          <div className="flex py-6 ">
            <a
              href={links.twiter}
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineTwitter size={40} />{" "}
            </a>
            <a
              href={links.instagram}
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineInstagram size={40} />{" "}
            </a>
            <a
              href={links.facebook}
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineFacebook size={40} />{" "}
            </a>
            <a
              href={links.linkedin}
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineLinkedin size={40} />{" "}
            </a>
            <a
              href={links.youtube}
              className="pr-4 inline-block text-accent hover:text-white"
              target="_blank"
            >
              {" "}
              <AiOutlineYoutube size={40} />{" "}
            </a>
          </div>

          <a
            href="/#projects"
            className=" btn bg-accent border-2 border-[#7477FF] text-white px-20 py-3 hover:bg-transparent rounded-md"
          >
            {keywords.seeProjects}
          </a>
        </div>

        <div className="hero-img">
          <img
            src={globalImages.main}
            alt="coding illustration"
            className="lgw-[80%] ml-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
