import React from "react";
import { keywords } from "../utils/keywords";
import { globalImages } from "../utils/images";

const About = () => {
  return (
    <section className="bg-secondery text-white px-5 py-32" id="about">
      <div className="container mx-auto grid md:grid-cols-2 items-center justify-center md:justify-between">
        <div className="about-info">
          <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[180px] border-indigo-600 pb-2">
            {keywords.aboutUs}
          </h2>
          <p className="pb-5">{keywords.aboutUsPara1}</p>
          <p className="pb-5">{keywords.aboutUsPara2}</p>
          <p className="pb-5">{keywords.aboutUsPara3}</p>
        </div>

        <div className="about-img">
          <img
            src={globalImages.about}
            alt="coding illustration"
            className="lgw-[80%] md:ml-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default About;
