import { hamdaniaAppImages, hamdaniaFeatures, hamdaniaKeywords, technologiesUsedInHamdania } from "./hamdania";
import { mobuAppImages, mobuFeatures, mobuKeywords, technologiesUsedInMobu } from "./mobu";
// import {
//   mobuFeatures,
//   trueBondKeywords,
//   technologiesUsedIntrueBond,
//   trueBondAppImages,
// } from "./trueBond";

export const ourProjects = [
  // {
  //   img: trueBondAppImages[0],
  //   title: trueBondKeywords.name,
  //   desc: trueBondKeywords.description,
  //   video: "",
  //   videoLink: trueBondKeywords.videoLink,
  //   images: trueBondAppImages,
  //   detailDesc: trueBondKeywords.detail,
  //   portfolioItems: trueBondFeatures,
  //   technologiesUsed: technologiesUsedIntrueBond,
  // },
  {
    img: mobuAppImages[0],
    title: mobuKeywords.name,
    desc: mobuKeywords.description,
    video: "",
    videoLink: mobuKeywords.videoLink,
    images: mobuAppImages,
    detailDesc: mobuKeywords.detail,
    portfolioItems: mobuFeatures,
    technologiesUsed: technologiesUsedInMobu,
  },
  {
    img: hamdaniaAppImages[0],
    title: hamdaniaKeywords.name,
    desc: hamdaniaKeywords.description,
    video: "",
    videoLink: hamdaniaKeywords.videoLink,
    images: hamdaniaAppImages,
    detailDesc: hamdaniaKeywords.detail,
    portfolioItems: hamdaniaFeatures,
    technologiesUsed: technologiesUsedInHamdania,
  },
];
