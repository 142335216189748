import { technologyImages } from "../utils/images";
import { technologies } from "../utils/keywords";

export const clientsData = [
  {
    id: 1,
    image: "https://randomuser.me/api/portraits/men/1.jpg",
    name: "Muhammad Rasheed",
    comment: "InnuNext Technologies delivered amazing results for our project.",
    rating: 5,
  },
  {
    id: 2,
    image: "https://randomuser.me/api/portraits/women/2.jpg",
    name: "Sophie Williams",
    comment: "Highly professional team! Very satisfied with the outcome.",
    rating: 4,
  },
  {
    id: 3,
    image: "https://randomuser.me/api/portraits/men/3.jpg",
    name: "Liam Anderson",
    comment:
      "Great communication and excellent support throughout the process.",
    rating: 5,
  },
  {
    id: 4,
    image: "https://randomuser.me/api/portraits/women/4.jpg",
    name: "Olivia Thompson",
    comment: "Outstanding experience from start to finish!",
    rating: 5,
  },
  {
    id: 5,
    image: "https://randomuser.me/api/portraits/men/5.jpg",
    name: "Ethan Roberts",
    comment: "The team is highly skilled and professional.",
    rating: 4,
  },
];

export const technologiesIcons = {
  images: [
    technologyImages.css,
    technologyImages.firebase,
    technologyImages.html,
    technologyImages.react,
    technologyImages.javascript,
    technologyImages.mui,
    technologyImages.nodejs,
  ],
};

export const frontendTechnologies = {
  images: [
    { logo: technologyImages.react, title: technologies.reactNative },
    { logo: technologyImages.javascript, title: technologies.javascript },
    { logo: technologyImages.typescript, title: technologies.typescript },
    { logo: technologyImages.react, title: technologies.react },
    { logo: technologyImages.tailwind, title: technologies.tailwind },
  ],
};

export const backendTechnologies = {
  images: [
    { logo: technologyImages.nodejs, title: technologies.node },
    { logo: technologyImages.firebase, title: technologies.firebase },
    { logo: technologyImages.supabase, title: technologies.supabase },
    { logo: technologyImages.mongodb, title: technologies.mongodb },
    { logo: technologyImages.postgres, title: technologies.postgresql },
  ],
};

export const applications = {
  images: [
    { logo: technologyImages.react, title: technologies.reactNative },
    { logo: technologyImages.swift, title: technologies.swift },
    { logo: technologyImages.android, title: technologies.androidStudio },
    { logo: technologyImages.java, title: technologies.java },
    { logo: technologyImages.xcode, title: technologies.xcode },
  ],
};

export const devOps = {
  images: [
    { logo: technologyImages.aws, title: technologies.aws },
    { logo: technologyImages.docker, title: technologies.docker },
    { logo: technologyImages.cicd, title: technologies.cicd },
    { logo: technologyImages.kubernetes, title: technologies.kubernetes },
  ],
};

export const teams = [
  {
    id: 0,
    name: "Inayat Ali",
    role: "CEO",
    image: "./inayat.jpg",
  },
  // {
  //   id: 1,
  //   name: "Walayat Ali",
  //   role: "Senior Frond-end developer",
  //   image: "./walayat.jpeg",
  // },
  // {
  //   id: 2,
  //   name: "Wilayat Daniyal",
  //   role: "Senior Backend developer",
  //   image: null,
  // },
  {
    id: 3,
    name: "Asma Noor",
    role: "Human Resource Manager (HR)",
    image: "./female.png",
  },
  {
    id: 4,
    name: "Sardar Hussain",
    role: "React Native (Intern)",
    image: "./sardar.jpg",
  },
  {
    id: 5,
    name: "Muhammad Hadi",
    role: "Graphic Designer (intern)",
    image: "./hadi.jpg",
  },
];

export const links = {
  twiter: "https://x.com/InnuNextTech",
  linkedin: "https://www.linkedin.com/company/104642441/admin/dashboard/",
  instagram: "https://www.instagram.com/innunext/",
  facebook: "https://www.facebook.com/profile.php?id=61565176560771",
  youtube: "https://www.youtube.com/channel/UCA1gqWpr9KYwGZSsfW8r9tQ",
  github: "https://github.com/Inayat567",
};
