import { useState } from "react";
import { ourProjects } from "../constants/projects";
import Modal from "./Projects/model";
import { keywords } from "../utils/keywords";

const Projects = () => {
  let [isOpen, setIsOpen] = useState(false);
  let [selectedProject, setSelectedProject] = useState(ourProjects[0]);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <section className="bg-secondery text-white px-5 py-32" id="projects">
      <div className="container mx-auto grid md:grid-cols-2 items-center md:justify-between">
        <div className="about-info mb-5">
          <h2 className="text-4xl font-bold mb-5 border-b-[5px] w-[180px] border-indigo-600 pb-2">
            {keywords.projects}
          </h2>
          <p className="pb-5">{keywords.projectDescription}</p>
        </div>

        <div className="about-img"></div>
      </div>

      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        project={selectedProject}
      />

      <div className="projects container mx-auto grid md:grid-cols-3 gap-10">
        {ourProjects.map((project, index) => {
          return (
            <div className="relative" key={index}>
              <img
                src={project.img}
                alt={project.title}
                style={{
                  width: 550,
                  height: 500,
                  borderRadius: 20,
                  objectFit: "fill",
                }}
              />
              <div className="flex absolute left-0 right-0 top-[13px] rounded bottom-0 mx-auto w-[90%] h-[90%] bg-primary opacity-0 duration-500 justify-center flex-col hover:opacity-90">
               <h1 className="py-5 text-4xl text-center font-bold px-2 text-white ">
                  {project.title}
                </h1>
                <p className="py-5 text-center font-bold px-2 text-white">
                  {project.desc}
                </p>

                <div
                  onClick={() => {
                    setSelectedProject(project);
                    openModal();
                  }}
                  className="mx-auto btn bg-accent  border-2 border-[#7477FF] text-white px-6 py-3 hover:bg-transparent"
                >
                  {keywords.moreDetails}
                </div>
                {project.videoLink && (
                  <div className="mt-1 mx-auto btn bg-accent  border-2 border-[#7477FF] text-white px-6 py-3 hover:bg-transparent">
                    <a href={project.videoLink} target="_blank">
                      {keywords.videoDemo}
                    </a>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Projects;
