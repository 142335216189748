export const globalImages = {
  about: require("../assets/about.png"),
  blog: require("../assets/blog.png"),
  blog1: require("../assets/blog1.png"),
  contactUs: require("../assets/contact-us.png"),
  main: require("../assets/main.png"),
};

export const technologyImages = {
  android: require("../assets/technology/android.png"),
  aws: require("../assets/technology/aws.png"),
  cicd: require("../assets/technology/cicd.png"),
  css: require("../assets/technology/css.png"),
  docker: require("../assets/technology/docker.png"),
  firebase: require("../assets/technology/firebase.png"),
  java: require("../assets/technology/java.png"),
  javascript: require("../assets/technology/javascript.png"),
  html: require("../assets/technology/html.png"),
  kubernetes: require("../assets/technology/kubernetes.png"),
  mongodb: require("../assets/technology/mongodb.png"),
  mui: require("../assets/technology/mui.png"),
  nodejs: require("../assets/technology/nodejs.png"),
  postgres: require("../assets/technology/postgre.png"),
  python: require("../assets/technology/python.png"),
  react: require("../assets/technology/react.png"),
  supabase: require("../assets/technology/supabase.png"),
  swift: require("../assets/technology/swift.png"),
  tailwind: require("../assets/technology/tailwind.png"),
  typescript: require("../assets/technology/typescript.png"),
  xcode: require("../assets/technology/xcode.png"),
  twilio: require("../assets/technology/twilio.png"),
};

export const trueBondImages = {
  img1: require("../assets/projects/trueBond/img1.jpeg"),
  img2: require("../assets/projects/trueBond/img2.jpeg"),
  img3: require("../assets/projects/trueBond/img3.jpeg"),
  img4: require("../assets/projects/trueBond/img4.jpeg"),
};

export const mobuImages = {
  img1: require("../assets/projects/mobu/img1.png"),
  img2: require("../assets/projects/mobu/img2.png"),
  img3: require("../assets/projects/mobu/img3.png"),
  img4: require("../assets/projects/mobu/img4.png"),
  img5: require("../assets/projects/mobu/img5.png"),
  img6: require("../assets/projects/mobu/img6.png"),
  img7: require("../assets/projects/mobu/img7.png"),
  img8: require("../assets/projects/mobu/img8.png"),
  img9: require("../assets/projects/mobu/img9.png"),
  img10: require("../assets/projects/mobu/img10.png"),
  img11: require("../assets/projects/mobu/img11.png"),
  img12: require("../assets/projects/mobu/img12.png"),
  img13: require("../assets/projects/mobu/img13.png"),
  img14: require("../assets/projects/mobu/img14.png"),
  img15: require("../assets/projects/mobu/img15.png"),
  img16: require("../assets/projects/mobu/img16.png"),
  img17: require("../assets/projects/mobu/img17.png"),
  img18: require("../assets/projects/mobu/img18.png"),
  img19: require("../assets/projects/mobu/img19.png"),
  img20: require("../assets/projects/mobu/img20.png"),
  img21: require("../assets/projects/mobu/img21.png"),
  img22: require("../assets/projects/mobu/img22.png"),
  img23: require("../assets/projects/mobu/img23.png"),
  img24: require("../assets/projects/mobu/img24.png"),
  img25: require("../assets/projects/mobu/img25.png"),
  img26: require("../assets/projects/mobu/img26.png"),
  img27: require("../assets/projects/mobu/img27.png"),
  img28: require("../assets/projects/mobu/img28.png"),
  img29: require("../assets/projects/mobu/img29.png"),
  img30: require("../assets/projects/mobu/img30.png"),
  img31: require("../assets/projects/mobu/img31.png"),
};

export const hamdaniaImages = {
  logo: require("../assets/projects/hamdania/logo.png"),
  splash: require("../assets/projects/hamdania/splash.png"),
  img1: require("../assets/projects/hamdania/img1.png"),
  img2: require("../assets/projects/hamdania/img2.png"),
  img3: require("../assets/projects/hamdania/img3.png"),
  img4: require("../assets/projects/hamdania/img4.png"),
  img5: require("../assets/projects/hamdania/img5.png"),
  img6: require("../assets/projects/hamdania/img6.png"),
  img7: require("../assets/projects/hamdania/img7.png"),
  img8: require("../assets/projects/hamdania/img8.png"),
  img9: require("../assets/projects/hamdania/img9.png"),
  img10: require("../assets/projects/hamdania/img10.png"),
  img11: require("../assets/projects/hamdania/img11.png"),
  img12: require("../assets/projects/hamdania/img12.png"),
  img13: require("../assets/projects/hamdania/img13.png"),
  img14: require("../assets/projects/hamdania/img14.png"),
};
