import { useState, useEffect } from "react";
import "../App.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { clientsData } from "src/constants";
import { keywords } from "src/utils/keywords";
import { Client } from "src/utils/types";

export default function Clients() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [animationState, setAnimationState] = useState("");

  const handleNavigation = (direction: string) => {
    setAnimationState(direction);
  };

  const getVisibleClients = (count: number) => {
    const total = clientsData.length;
    return Array.from({ length: count }, (_, i) => {
      const index =
        animationState === "animate-prev"
          ? (currentIndex - i + total) % total
          : (currentIndex + i) % total;
      return clientsData[index];
    }).reverse();
  };

  const visibleClients = getVisibleClients(3);
  const visibleClientsSmallScreens = getVisibleClients(1);

  useEffect(() => {
    if (animationState) {
      const timer = setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          animationState === "animate-next"
            ? (prevIndex + 1) % clientsData.length
            : (prevIndex - 1 + clientsData.length) % clientsData.length
        );
        setAnimationState("");
      }, 10); // Match animation duration
      return () => clearTimeout(timer);
    }
  }, [animationState]);

  const ClientCard = ({
    client,
    sizeClass,
  }: {
    client: Client;
    sizeClass?: string;
  }) => (
    <div
      key={client.id}
      className={`${sizeClass} bg-primary rounded-lg shadow-lg p-6 min-h-[400px] text-center text-white transition-transform duration-500 ease-in-out`}
    >
      <div className="w-24 h-24 mx-auto rounded-full overflow-hidden mb-2">
        <img
          src={client.image}
          alt={client.name}
          className="w-full h-full object-cover"
          width={96}
          height={96}
          loading="lazy"
        />
      </div>
      <h3 className="text-xl font-semibold">{client.name}</h3>
      <p className="italic max-h-[300px] sm:px:5 md:px-0 lg:px-16 py-5 overflow-hidden">
        "{client.comment}"
      </p>
      <div className="text-yellow-500 mt-4">{"⭐".repeat(client.rating)}</div>
    </div>
  );

  return (
    <section className="py-20 bg-secondery relative" id="clients">
      <div className="container mx-auto grid md:grid-cols-2 items-center md:justify-between">
        <div className="about-info mb-5">
          <h2 className="w-[190px] text-4xl text-white font-bold mb-5 border-b-[5px] w-[180px] border-indigo-600 pb-2">
            {keywords.ourClients}
          </h2>
          <p className="pb-5 text-white">{keywords.ourClientMessage}</p>
        </div>
      </div>

      <div className="container mx-auto px-6 md:px-12 lg:px-8">
        <button
          onClick={() => handleNavigation("animate-prev")}
          className="absolute left-2 sm:left-10 top-[60%] transform -translate-y-1/2 text-gray-500 bg-primary rounded-full p-3 hover:text-white focus:outline-none z-10"
        >
          <FaChevronLeft size={24} />
        </button>

        <div className="flex justify-center items-center py-12 relative">
          {/* hidden sm:grid grid-cols-3 gap-8 */}
          <div className="hidden sm:grid grid grid-cols-3  gap-8 transition-transform duration-500 ease-in-out">
            {visibleClients.map((client, index) => {
              const sizeClass =
                index === 1
                  ? "transform scale-110 z-10"
                  : "transform scale-90 opacity-80";
              return (
                <ClientCard
                  key={client.id}
                  client={client}
                  sizeClass={sizeClass}
                />
              );
            })}
          </div>

          {/* For smaller screens */}
          <div className="grid grid-cols-1 gap-8 sm:hidden">
            {visibleClientsSmallScreens.map((client) => (
              <ClientCard key={client.id} client={client} />
            ))}
          </div>
        </div>

        <button
          onClick={() => handleNavigation("animate-next")}
          className="absolute right-2 sm:right-10 top-[60%] transform -translate-y-1/2 text-gray-500 bg-primary hover:text-white rounded-full p-3 focus:outline-none z-10"
        >
          <FaChevronRight size={24} />
        </button>
      </div>
    </section>
  );
}
