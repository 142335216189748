import { mobuImages, technologyImages } from "../utils/images";
import { technologies } from "../utils/keywords";

export const technologiesUsedInMobu = [
  { title: technologies.reactNative, logo: technologyImages.react },
  { title: technologies.typescript, logo: technologyImages.typescript },
  { title: technologies.node, logo: technologyImages.nodejs },
  { title: technologies.aws, logo: technologyImages.aws },
  { title: technologies.twilio, logo: technologyImages.twilio },
];

export const mobuFeatures = [
    {
      title: "Service Provider App (Mobu)",
      description:
        "Mobu is a service provider app that connects clients and providers for various services. It features three user roles: Admin, Provider, and Client, each with tailored functionalities to ensure seamless service management and booking.",
      color: "bg-blue-100",
    },
    {
      title: "User Roles",
      description:
        "Mobu supports three types of users: Admin, Providers, and Clients. Each role has specific capabilities to manage services, bookings, and interactions.",
      color: "bg-green-100",
    },
    {
      title: "Admin Features",
      description:
        "Admins can review and verify provider businesses, approve or reject business registrations, and manage reports submitted by clients. Businesses remain hidden from clients until approved by the admin.",
      color: "bg-indigo-100",
    },
    {
      title: "Provider Features",
      description:
        "Providers can create and manage their business, offer multiple services, set business hours, accept/reject bookings, book their services for others, and track their business progress through charts. Providers can also follow other users and chat with clients.",
      color: "bg-pink-100",
    },
    {
      title: "Client Features",
      description:
        "Clients can search for businesses (with a nearby search option), book services, chat with providers, report or block providers, review services, and follow providers. Clients can also track their bookings and payment history.",
      color: "bg-yellow-100",
    },
    {
      title: "Service Booking and Notifications",
      description:
        "Clients can book services for specific dates and times, while providers can accept or reject bookings. Accepted bookings block the corresponding date and time on the provider's calendar to avoid overlaps. Notifications are sent for booking updates.",
      color: "bg-red-100",
    },
    {
      title: "Payment Gateway Integration",
      description:
        "Both clients and providers can securely add and manage their payment methods via Stripe. Payments for completed services are handled seamlessly within the app.",
      color: "bg-purple-100",
    },
    {
      title: "Real-Time Chat",
      description:
        "Clients and providers can communicate directly via a real-time chat feature, making coordination and service management smoother.",
      color: "bg-orange-100",
    },
    {
      title: "Progress and Analytics",
      description:
        "Providers can track their business growth and performance with built-in charts, offering insights into completed bookings and client satisfaction.",
      color: "bg-teal-100",
    },
    {
      title: "Follow and Followers",
      description:
        "Mobu allows users to follow each other. Providers can build a network of followers, and clients can stay updated with their favorite service providers.",
      color: "bg-cyan-100",
    },
    {
      title: "Nearby Business Search",
      description:
        "Clients can find nearby businesses and services using geolocation, making it easier to book services from local providers.",
      color: "bg-gray-100",
    },
  ];
  

export const mobuKeywords = {
  name: "Mobu",
  description: "Service provider app in React Native",
  videoLink: "",
  detail: "",
};

export const mobuAppImages = [
  mobuImages.img1,
  mobuImages.img2,
  mobuImages.img3,
  mobuImages.img4,
  mobuImages.img5,
  mobuImages.img6,
  mobuImages.img7,
  mobuImages.img8,
  mobuImages.img9,
  mobuImages.img10,
  mobuImages.img11,
  mobuImages.img12,
  mobuImages.img13,
  mobuImages.img14,
  mobuImages.img15,
  mobuImages.img16,
  mobuImages.img17,
  mobuImages.img18,
  mobuImages.img19,
  mobuImages.img20,
  mobuImages.img21,
  mobuImages.img22,
  mobuImages.img23,
  mobuImages.img24,
  mobuImages.img25,
  mobuImages.img26,
  mobuImages.img27,
  mobuImages.img28,
  mobuImages.img29,
  mobuImages.img30,
  mobuImages.img31,
];
