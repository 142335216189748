import { NBFProp } from "src/utils/types";

const NBF_Admin = ({ portfolioItems, technologiesUsed, title }: NBFProp) => {
  return (
    <div className="p-4 text-center">
      <h2 className="text-3xl font-semibold mb-6">{title}</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {portfolioItems.map((item, index) => (
          <div key={index} className={`p-6 rounded-lg shadow-md ${item.color}`}>
            <h3 className="text-xl font-semibold mb-2">{item.title}</h3>
            <p className="text-gray-700">{item.description}</p>
          </div>
        ))}
      </div>
      <h2 className="text-3xl font-semibold mt-8">Technologies Used</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {technologiesUsed.map((tech, index) => (
          <div
            key={index}
            className="bg-white p-4 rounded-lg shadow-md flex flex-col items-center"
          >
            <img
              src={tech.logo}
              alt={tech.title}
              className="w-16 h-16 object-contain"
            />
            <h3 className="text-lg font-semibold mt-2">{tech.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};
export default NBF_Admin;
